import React from 'react'
import Layout from '../components/Layout'

const About = () => {
    return (
        <Layout>    
            <section>
            <div className="container">
                <h2> My Bio</h2>
                <br />
            <p>  
            Self-motivated professional in Information Technology. Specifically, in Global IT Applications and Infrastructure, Software Development, Custom front-end and server-side application development using full stack technologies. Designed Business Intelligence platforms for large Enterprises. Developed hundreds of custom dashboards, and visualizations using various BI frameworks. Expert level knowledge in Business Intelligence Systems, Systems Architecture, Application Development, Solutions Architecture, Correlative Graph Analytics, Data Mining, Advanced Media Streaming Technologies, REST and GraphQL APIs, Blockchain and Distributed DeFi crypto applications. 
            
            I am passionate about Machine Learning, and Neural Networks and NLP systems using speech recognition to power advances web applications.
            </p> 

            <p>
            I designed and built dozens of Business Intelligence Systems using various BI technologies, including Birst, Tableau, QlikView, WebFocus, Looker, Crystal Reports, SQL, PL/SQL as well as desktop and mobile applications, from inception to production implementation. I have managed multiple small to medium size teams ranging from application developers, integration engineers, database administrators, developers, and business intelligence developers. 
            </p>

            <p>I have had many opportunities to hold positions of Owner, CoFounder, Director of Systems, Project Director, Sr. IT Manager, BI Architect, Consultant, Developer, and with experience in directing multi-million-dollar projects. I have coded in many languages and recently have updated my skill set with over 9 programming certificates. I am now focusing on Graph Database Analytics, Machine Learning, and Blockchain Technologies. I believe with just these three technologies, any medium to large corporation can benefit greatly. </p>

            <p>My primary goal is to help companies utilize current and emerging new technologies to achieve innovative and cost-effective solutions to drive their business to the next level.  </p>
            </div>
            </section>
        </Layout>
    )
}

export default About
